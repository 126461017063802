import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID } from '@angular/core';

import { COUNTRY_ID } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/l10n/country';
import { LocaleStaticLoaderModule } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/l10n/locale-static-loader';
import { TranslateStaticLoader } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/l10n/translate-static-loader';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateModule } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/l10n/translation';
import { IconErrorModule } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/components/icons';
import { AlertModule } from '@sailpoint-priv/armada-angular/src/sailpoint/angular/components/alert';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import {
  LoadingMaskModule
} from '@sailpoint-priv/armada-angular/src/sailpoint/angular/components/loading-mask';
import { DashboardLayoutModule } from "@shared/layouts/dashboard-layout";
import { SidebarModule } from "@shared/layouts/sidebar/sidebar.module";
import { HeaderModule } from "@shared/layouts/header/header.module";
import { AuthInterceptor } from "@core/interceptors/auth.interceptor";
import { HttpErrorInterceptor } from "@core/interceptors/http-error.interceptor";
import {
  IconCompleteModule
} from "@sailpoint-priv/armada-angular/src/sailpoint/angular/components/icons";
import { ApiUrlInterceptor } from "@core/interceptors/api-url.interceptor";

import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';

import 'codemirror/mode/xml/xml';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,

    AmplifyAuthenticatorModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LocaleStaticLoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateStaticLoader
      },
      defaultLanguage: 'en',
    }),
    NgbModule,
    NgbPopoverModule,
    IconCompleteModule,
    AlertModule,
    IconErrorModule,
    LoadingMaskModule,
    DashboardLayoutModule,
    SidebarModule,
    HeaderModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
    { provide: COUNTRY_ID, useValue: 'US' },
    { provide: HTTP_INTERCEPTORS, useClass: ApiUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
