import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { TokenService } from "@core/services/token.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handleAuth(next, req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handleUnauthorizedError(next, req);
        }
        return throwError(error);
      })
    );
  }

  private handleUnauthorizedError(next: HttpHandler, req: HttpRequest<any>) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

    }

    return this.tokenService.getToken$().pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.handleAuth(next, req))
    );
  }

  private handleAuth(next: HttpHandler, req: HttpRequest<any>) {
    return next.handle(this.authReq(req));
  }

  private authReq(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.tokenService.getToken()}`,
      },
    });
  }
}
