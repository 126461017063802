import { Injectable, OnDestroy } from '@angular/core';
import { signOut } from 'aws-amplify/auth';
import { environment } from 'src/environments/environment';
import { UserService } from "@core/services/user.service";
import { TokenService } from "@core/services/token.service";
import { BehaviorSubject, Observable, of, Subscription, timer } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";

import { AuthenticatorService } from '@aws-amplify/ui-angular'
import { signInWithRedirect } from 'aws-amplify/auth';

import { awsmobile } from 'src/aws-exports';
@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private authLink = `https://${awsmobile.Auth.Cognito.loginWith.oauth.domain}/login?response_type=code&client_id=${awsmobile.Auth.Cognito.userPoolClientId}&redirect_uri=${environment.redirectSignIn}`;
  
  private authSubscription: Subscription;
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  
  constructor(private userService: UserService, private tokenService: TokenService, private authenticator: AuthenticatorService) {
  }

  public logout(): void {
    signOut().then();
  }

  public authenticated(): Observable<boolean> {
    // Create a timer that emits every 100ms
    return timer(0, 100).pipe(
      // Switch to checking the auth state on each timer tick
      switchMap(() => of(this.authenticator.authStatus)),
      // Filter out 'configuring' state
      filter(state => state !== 'configuring'),
      // Take only the first non-'configuring' state
      take(1),
      // Map to boolean
      map(state => {
        if (this.authenticator.authStatus==='authenticated') {
          this.setAuthData(this.tokenService.getToken());
          return true;
        } else {
          signInWithRedirect();
          return false;
        }
      })
    );
  }

  private setAuthData(authData: any) {
    this.userService.setAuthUser(this.tokenService.getDecodedToken());
  }

  ngOnDestroy() {
    // Clean up subscription when service is destroyed
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
